import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import Cta from '../components/cta'
import ListCompanies from '../components/listCompanies'

library.add(fat)

const Partner = ({ data, location }) => {
  const post = data.markdownRemark
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"  

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>

            <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.openingpara,
                  }}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>             
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section.map((sections, index) => (
          <section className="bg-light-blue" key={index}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="pb-5">
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3">
                  {sections.title}
                </h2>
                  {sections.subtitle && (
                    <p className="text-medium">{sections.subtitle}</p>
                  )}                  
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {sections.feature && (
                    <ListCompanies data={sections.feature} btnId={btnId} />
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ))}

        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </main>
    </Layout>
  )
}
export default Partner

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {   
    markdownRemark(frontmatter: { name: { eq: "Partner" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        ctatext
        ctaslug
        openingpara 
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          subtitle
          feature {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            placement  
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`