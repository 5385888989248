import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'
import { faCircleChevronRight } from '@fortawesome/pro-solid-svg-icons'

function properCase(str) {
  return str
    .split('-')
    .map((s) => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
}

const Sitemap = ({ data }) => {
  const news = data.news.edges
  const heroImage = data.heroImage
  const legal = data.legal.edges
  const services = data.services.edges
  const products = data.products.edges
  const events = data.events.edges

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="iET Solutions Seitenübersicht"
          subtitle=""
          type="sitemap"
        />
      </div>
      <section className="bg-white">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="8">
                <MDBRow>
                  <MDBCol md="6" className="mb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mb-5 mt-lg-5">
                      Startseite
                      <Link to="/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5">
                      Unternehmen
                      <Link to="/ueber/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <p className="font-w-400 text-medium">
                      <Link to="/karriere/" className="effect-noline">
                        Karriere
                      </Link>
                    </p>      
                    <p className="font-w-400 text-medium">
                      <Link to="/kontakt/" className="effect-noline">
                        Kontakt
                      </Link>
                    </p>    
                    <p className="font-w-400 text-medium">
                      <Link to="/leadership/" className="effect-noline">
                        Leadership
                      </Link>
                    </p>                                                  
                    <p className="font-w-400 text-medium">
                      <Link to="/ueber/" className="effect-noline">
                        Über
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium mb-3">
                      <Link to="/informationsmaterial/" className="effect-noline">
                        Informationsmaterial
                      </Link>
                    </p>
                    <ul className="fa-ul text-card-small">
                      <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                          <Link to="/informationsmaterial/anwenderberichte/" className="effect-noline">Anwenderberichte</Link>
                      </li>
                      <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                          <Link to="/informationsmaterial/datenblaetter/" className="effect-noline">Datenblätter</Link>
                      </li>
                      <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                          <Link to="/informationsmaterial/enewsletter/" className="effect-noline">eNewsletters</Link>
                      </li>                                        
                      <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                          <Link to="/informationsmaterial/whitepaper/" className="effect-noline">Whitepaper</Link>
                      </li>
                    </ul>                                          
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      UNICOM Global Divisions
                      <Link to="/unicom-global/divisions/" className="ml-2 linkedin" >
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Search
                      <Link to="/search/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                    </h2>                    
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Privacy &amp; Legal
                    </h2>
                    {legal.sort((a,b) => a.node.frontmatter.title.toUpperCase() == b.node.frontmatter.title.toUpperCase() ? 0 : a.node.frontmatter.title.toUpperCase() < b.node.frontmatter.title.toUpperCase() ? -1 : 1).map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link>
                      </p>
                    ))}             
                     
                  </MDBCol>

                  <MDBCol md="6" className="mb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Serviceangebot
                      <Link to="/serviceangebot/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                    </h2>
                    {services.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link>
                      </p>
                    ))}                  
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Presse
                      <Link to="/news/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                    </h2>
                    {news.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={`/news/${node.slug + `/`}`} className="effect-noline" > {properCase(node.title)} </Link>
                      </p>
                    ))}
                    <ul className="fa-ul text-medium ml-4">
                      <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                          <Link to="/news/" className="effect-noline">Weitere Presse</Link>
                      </li>
                    </ul>                     
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Veranstaltungen
                      <Link to="/veranstaltungen/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                    </h2>
                    {events.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={`/veranstaltungen/${node.slug + `/`}`} className="effect-noline" > {properCase(node.title)} </Link>
                      </p>
                    ))}
                    <ul className="fa-ul text-medium ml-4">
                      <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                          <Link to="/veranstaltungen/" className="effect-noline">Weitere Veranstaltungen</Link>
                      </li>
                    </ul>     
                  </MDBCol>
                </MDBRow>
              </MDBCol>

              <MDBCol md="4" className="mb-5">
                <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5">
                  Produkte
                  <Link to="/produkte/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                </h2>
                {products.sort((a,b) => a.node.frontmatter.title.toUpperCase() == b.node.frontmatter.title.toUpperCase() ? 0 : a.node.frontmatter.title.toUpperCase() < b.node.frontmatter.title.toUpperCase() ? -1 : 1).map(({ node }, index) => (
                  <p className="font-w-400 text-medium" key={index}>
                    <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link>
                  </p>
                ))}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBAnimation>
      </section>
    </Layout>
  )
}
export default Sitemap

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="iET Solutions - Seitenübersicht"
      description="Use this sitemap to find the content you're looking for and then navigate your way around the iET Solutions website."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                        
    />
  )
}

export const query = graphql`
  query SitemapQuery {
    news: allContentfulNews(
      filter: {
        publishTo: { elemMatch: { url: { eq: "https://www.iet-solutions.de" } } }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }
    events: allContentfulEvent(
      filter: {
        publishTo: { elemMatch: { url: { eq: "https://www.iet-solutions.de" } } }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }    
    products: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "produkte" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    services: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "serviceangebot" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    legal: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "legal" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    about: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "uber" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    heroImage: file(name: { eq: "sitemap-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
